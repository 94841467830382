
.bg-col-1{
    /* background-color: rgb(26, 21, 96); */
    background-color: rgb(10, 9, 9);
    height: 100vh;
}

#particles-js{ 
    position:absolute; 
    width: 100%; 
    height: 100%; 
    background-repeat: no-repeat; 
    background-size: cover;
    background-position: 50% 50%;   
    background-color: black;
}

.softroniiks-logo{
    width: 90%;
    justify-content: center;
    position: absolute;
    top: 90px;
    right: 60px;
}

.quotes{
    font-size: 22px;
    font-family: 'Courier New', Courier, monospace;
    position: absolute;
    top: 300px;
    font-weight: 600;
}

.hammer-icon{
    width: 24px;
    height: 24px;
}

.invent-icon{
    width: 32px;
    height: 32px;
}

.afrowatch-logo{
    width: 80px;
    position: absolute;
    bottom: 5px;
    margin-left: -77px;
}

@media screen and (max-width: 600px) {
    .softroniiks-logo{
        position: absolute;
        top: 55px;
        right: 20px;
    }

    .bg-col-1{
        background-color: rgb(10, 9, 9);
        height: 50vh;
    }

    .quotes{
        font-size: 22px;
        font-family: 'Courier New', Courier, monospace;
        position: absolute;
        top: 30px;
        font-weight: 600;
    }

    .afrowatch-logo{
        width: 60px;
        position: absolute;
        top: 350px;
        right: px;
        left: 160px;
    }
}